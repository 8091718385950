.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.popup-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #888;
}

.close:hover {
  color: #000;
}

.small-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.small-table th,
.small-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.small-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
