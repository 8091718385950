table {
  font-family: 'UGent', sans-serif;
  font-size: 14px;
  color: #000;
  border-collapse: collapse;
  width: 80%;
}

table td,
table th {
  text-align: left;
  border: 1px solid #ddd;
  padding: 4px;
}

table tr:nth-child(even) {
  background-color: #aaccfa;
}

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: #1e64c8;
  color: white;
}

.button-add {
  width: 80%;
  align-items: flex-end;
  margin-bottom: 5px;
}

.category-select {
  width: 250px;
  height: 25px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  border-color: #1e64c8;
}

.sub-category-select {
  margin-left: 20px;
  width: 250px;
  height: 25px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  border-color: #1e64c8;
}

.resource-search {
  margin-left: 20px;
  width: 250px;
  height: 25px;
  border-color: #1e64c8;
  font-size: 14px;
}

.resource-buttons {
  display: flex;
  justify-content: center;
}

button {
  /* margin-left: 20px; */
  font-family: 'Roboto', sans-serif;
  border-color: #1e64c8;
  background-color: white;
  font-size: 12px;
  height: 30px;
}

.button-review {
  margin-left: 25px;
}

.pagination-style {
  display: flex;
  flex-direction: row;
  height: 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  justify-content: center;
  align-content: center;
  border-color: black;
}

.goto-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-index {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-size-select {
  width: 125px;
  font-size: 12px;
  height: 30px;
}

.review-button {
  border-color: #1e64c8;
  background-color: white;
  margin-left: 20px;
  font-size: 14px;
}

.button-next {
  width: 100%;
  align-items: flex-end;
  margin-bottom: 5px;
  text-align: right;
}

.input-left {
  width: 80%;
  align-items: flex-start;
  margin-bottom: 5px;
  text-align: left;
}

.button-add {
  text-align: right;
}

i {
  cursor: pointer;
}

.checkbox {
  width: 25px;
}

.split {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 50;
  overflow-x: hidden;
  padding: 20px;
}

/* Control the left side */
.left {
  left: 0;
  width: '30%';
  margin-left: 50px;
}

/* Control the right side */
.right {
  right: 0;
  width: '70%';
  margin-right: 75px;
}

/* If you want the content centered horizontally and vertically */
.centered {
  /* position: absolute;
  top: 50%;
  left: 50%;*/
  justify-content: center;
  text-align: center;
}

.unavailable {
  background-color:  #FF9997 !important;
}

.button-align-left {
  display: flex !important;
  justify-content: left !important;
}