@import '~bootstrap/dist/css/bootstrap';
@import '~react-rrule-generator/build/styles';

.container {
  display: grid;
  grid-template-columns: 4fr 8fr;
}

.label {
  font-size: 0.8em;
}

td {
  text-align: left;
  width: max-content;
}

.btn.btn-primary {
  padding: 0 !important;
  background: gray
}
.btn.btn-primary.active {
  padding: 0 !important;
  background: blue
}

input#rrule-3-repeat-weekly-mon.form-control {
  width: 14px !important;
  height: 14px !important;
}

.btn-group.btn-group-toggle.offset-sm-2 {
  margin: 0 0 0 30px !important;
}

.row > * {
  flex-shrink: 0;
  width: 75%;
  max-width: 75%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
