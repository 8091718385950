* {
  font-family: 'UGent';
}



.error {
  color: red;
  font-size: 12px;
  text-align: left;
}


