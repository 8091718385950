@import '~bootstrap/dist/css/bootstrap.css';
@import '~react-rrule-generator/build/styles.css';

@font-face {
  font-family: 'UGent';
  src: local('UGent'),
    url('./font/ugentpannotext-normal-web.ttf') format('truetype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'UGent';
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
