.container {
  display: flex;
}

.left-section {
  width: 30%;
}

.right-section {
  width: 70%;
}

.split {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 50;
  overflow-x: hidden;
  padding: 20px;
}

/* Control the left side */
.left {
  left: 0;
  width: '30%';
  margin-left: 50px;
}

/* Control the right side */
.right {
  right: 0;
  width: '70%';
  margin-right: 75px;
}


