.IT {
  background-color:  #FF9997 !important;
}
.OZ {
  background-color: #A7E99C !important;
}
.OW {
  background-color: #cfcfc4 !important;
}
.VG {
  background-color:  #96B9D0 !important;
  
}