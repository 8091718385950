/* form {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
} */

.error {
    color: red;
    font-size: 12px;
    text-align: left
}

.unavailable {
  background-color:  #FF9997 !important;
}